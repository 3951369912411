

















import { Action } from "vuex-class";
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { FormBase, FormInput, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormBase,
    FormInput,
    FormError,
    FormSubmit,
  },
})
export default class ModalWorkspaceCreate extends Vue {
  @Action("workspace/create") createWorkspace!: (payload: WorkspacePayload) => Promise<{}>;

  @Prop() open!: boolean;

  @Ref() form!: FormClass;

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  payload: WorkspacePayload = {
    title: "",
    url: "",
  };

  private submit() {
    this.createWorkspace(this.payload)
      .then(() => this.$emit("close"))
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }
}
